<template>
  <div class="error">
    <div class="error-code m-b-10">404</div>
    <div class="error-content">
      <div class="error-message">We couldn't find it...</div>
      <div class="error-desc m-b-30">
        The page you're looking for doesn't exist.
      </div>
      <div>
        <router-link to="/" class="btn btn-primary p-l-20 p-r-20">
          <span>Go Home</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'NotFound' };
</script>
